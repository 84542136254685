import { parsePhoneNumberFromString } from 'libphonenumber-js'
import VATValidator from './VATValidator'
import moment from 'moment'

export const supportedCountries = ['ES']

const colors = [
  '#FF7878',
  '#7142A0',
  '#D55283',
  '#35BF8D',
  '#E55934',
  '#4299E1',
  '#3A6890',
  '#21BECB',
  '#FFD166',
  '#795548',
  '#5F3C54'
]

const currencyFormatters = (currency, milis = false) => {
  const config = {
    style: 'currency',
    currency: currency ?? 'EUR',
    maximumFractionDigits: milis ? 3 : 2
  }
  const formats = {
    EUR: 'es-ES',
    USD: 'en-EN',
    GBP: 'en-EN'
  }
  return new Intl.NumberFormat(formats[currency], config)
}

function colorIsDark(hexcolor) {
  let color = hexcolor.substr(1, 6)
  let r = parseInt(color.substring(0, 2), 16)
  let g = parseInt(color.substring(2, 4), 16)
  let b = parseInt(color.substring(4, 6), 16)
  let yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq < 200
}

function darken(color, percent = 10) {
  if (!color) return '#1D1C48'
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = Math.min(parseInt((R * (100 - percent)) / 100), 255)
  G = Math.min(parseInt((G * (100 - percent)) / 100), 255)
  B = Math.min(parseInt((B * (100 - percent)) / 100), 255)

  let RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
  let GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
  let BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

function stringHashCode(string) {
  let hash = 0,
    i,
    chr
  if (string.length === 0) return hash
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash)
}

function log(item) {
  // eslint-disable-next-line no-console
  console.log(item)
  return item
}

function getHashColor(string) {
  return colors[this.stringHashCode(string) % colors.length]
}

function getNthColor(n) {
  return colors[n % colors.length]
}

function round(num) {
  return Math.round(num * 100) / 100
}

function deepClone(data) {
  return JSON.parse(JSON.stringify(data))
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function currencyFilter(value, currency = 'EUR', milis = false) {
  const decimalValue = milis ? value / 1000 : value / 100
  if (!['USD', 'GBP', 'EUR'].includes(currency)) {
    // Fall back to euro with unsupported currencies, but this shouldn't happen
    return currencyFormatters('EUR', milis).format(decimalValue)
  }
  return currencyFormatters(currency, milis).format(decimalValue)
}

function objectsAreEqual(initialObject, object) {
  if (!initialObject || !object) return false
  return Object.keys(object).reduce((acc, key) => {
    if (acc && initialObject[key] !== object[key]) acc = false
    return acc
  }, true)
}

function formatNumber(phoneNumber) {
  if (!phoneNumber) return phoneNumber
  let parsedNumber = parsePhoneNumberFromString(phoneNumber, 'ES')
  return parsedNumber ? parsedNumber.number : phoneNumber
}

function debounce(func, delay) {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

function fuzzy(text, search) {
  ;[text, search] = [text, search].map(str => str.toLowerCase())
  let j = 0
  for (let i = 0; i < text.length; i++) {
    if (text[i] === search[j]) j++
  }
  return j === search.length
}

function formatEtaToRange(baseEta) {
  let eta = baseEta || 15
  let etaRoundedToNearest5 = Math.round(eta / 5) * 5
  return etaRoundedToNearest5 + '-' + (etaRoundedToNearest5 + 10)
}

function normalizeText(value) {
  return value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace("'", '')
}

function isNullOrEmpty(stringInput) {
  return stringInput == null || stringInput.trim() === ''
}

function getPercentageDiff(current, past) {
  if (!current || !past) return null
  let percentage = Math.round((current / past) * 100) - 100
  return {
    line: `${percentage > 0 ? '+' : '-'} ${Math.abs(percentage)} %`,
    color: percentage > 0 ? 'text-semantic-green' : 'text-error'
  }
}

function getCurrencyIcon(currencyCode) {
  switch (currencyCode) {
    case 'USD':
      return 'dollar'
    case 'GBP':
      return 'pound'
    default:
      return 'euro'
  }
}

function toKebabCase(text) {
  return text
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
}

function snakeToCamel(text) {
  return text.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', '')
  )
}

function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

function roundMinutes(minutes, multiple, floor) {
  let remainder = minutes % multiple
  return floor ? minutes - remainder : minutes + (multiple - remainder)
}

function taxIdIsValid(taxId) {
  return VATValidator.isValid(taxId)
}

function isIp(ip) {
  //validate 192.168.0.0/16 ip
  let ipRegex = /^192\.168\.\d{1,3}\.\d{1,3}$/
  //validate 10.0.0.0/8 ip
  let ipRegex2 = /^10\.\d{1,3}\.\d{1,3}\.\d{1,3}$/
  //validate 127.0.0.1 ip
  let ipRegex3 = /^127\.0\.0\.1$/
  //validate 172.16.0.0 to 172.31.255.255 ip
  let ipRegex4 = /^172\.(1[6-9]|2[0-9]|3[0-1])\.\d{1,3}\.\d{1,3}$/

  const numbers = ip.split('.')

  return (
    (ipRegex.test(ip) ||
      ipRegex2.test(ip) ||
      ipRegex3.test(ip) ||
      ipRegex4.test(ip)) &&
    numbers.every(number => number >= 0 && number <= 255)
  )
}

function isUuid4(text) {
  let uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  return uuidRegex.test(text)
}

function toChunks(array, chunkSize) {
  let chunks = []
  for (let i = 0, j = array.length; i < j; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

async function poll({ fn, n = 10, interval = 1000 }) {
  return new Promise(async (resolve, reject) => {
    let i = 0
    while (i < n) {
      const result = await fn()
      if (result) {
        return resolve(result)
      }
      i++
      await sleep(interval)
    }
    if (i === n) reject()
  })
}

function earliest(date1, date2) {
  if (!date1) return date2
  if (!date2) return date1
  return moment(date1).isBefore(date2) ? date1 : date2
}

function oldest(date1, date2) {
  if (!date1) return date2
  if (!date2) return date1
  return moment(date1).isBefore(date2) ? date2 : date1
}

function getSameLaboralDayFromLastYear(today) {
  let oneYearAgo = moment(today)
    .subtract(1, 'years')
    .week(moment(today).week())
    .day(moment(today).day())
  let oneYearAndOneDayAgo = moment(oneYearAgo).subtract(1, 'day')

  let oneYearAgoAsString = oneYearAgo.format()
  let oneYearAndOneDayAgoAsString = oneYearAndOneDayAgo.format()

  return {
    oneYearAgoAsString,
    oneYearAndOneDayAgoAsString
  }
}

function unique(items) {
  let dict = items.reduce((res, item) => {
    res[item.id] = item
    return res
  }, {})
  return Object.values(dict)
}

function index(array, indexValue) {
  return array.reduce((obj, item) => {
    if (item[indexValue] === undefined)
      throw new Error(`Key ${indexValue} not found in array`)
    obj[item[indexValue]] = item
    return obj
  }, {})
}

function addBusinessDays(date, days) {
  let newDate = moment(date)
  while (days > 0) {
    newDate.add(1, 'days')
    if (newDate.isoWeekday() < 6) days--
  }
  return newDate
}

function subtractBusinessDays(date, days) {
  let newDate = moment(date)
  while (days > 0) {
    newDate.subtract(1, 'days')
    if (newDate.isoWeekday() < 6) days--
  }
  return newDate
}

export default {
  stringHashCode,
  log,
  getHashColor,
  getNthColor,
  round,
  deepClone,
  sleep,
  currencyFilter,
  objectsAreEqual,
  darken,
  formatNumber,
  debounce,
  colors,
  formatEtaToRange,
  normalizeText,
  isNullOrEmpty,
  getPercentageDiff,
  getCurrencyIcon,
  colorIsDark,
  toKebabCase,
  snakeToCamel,
  capitalize,
  roundMinutes,
  taxIdIsValid,
  isIp,
  isUuid4,
  toChunks,
  poll,
  earliest,
  oldest,
  getSameLaboralDayFromLastYear,
  unique,
  fuzzy,
  index,
  addBusinessDays,
  subtractBusinessDays
}
